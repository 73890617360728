import { FC } from "react";
import styled from "styled-components";

export const LexiaListItem: FC<{
  children: Pick<string, "toString"> & { description?: string }
}> = ({ children: lexia }) => {
  if (!lexia.description) return lexia.toString();
  return <StyledItem><strong>{ lexia.toString() }</strong>&ensp;<em>{ lexia.description + '' }</em></StyledItem>;
};

const StyledItem = styled.span`
  position: absolute;
  left: 10px;
  right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #0006;
  font-size: 90%;
  padding: 0.5em 0;
  margin: -0.5em 0;

  strong {
    color: rgba(0, 0, 0, .87);
    font-size: 1rem;
    font-weight: normal;
  }
`;
