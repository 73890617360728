import { FC, useRef } from "react";
import { Icon, Menu } from "semantic-ui-react";
import StaticLinkMenu from "./StaticLinkMenu";
import { ContextMenuContainer } from "./styled";
import { ContextMenuProps } from "./types";
import { useContextMenu } from "./useContextMenu";
import { LinkType } from "../types";

const ContextMenu: FC<ContextMenuProps> = (props) => {
  const { activeSubMenu, openSubMenu, TOP_EDITOR_MENU_HEIGHT_PX } =
    useContextMenu<LinkType>(props);

  const containerRef = useRef<HTMLDivElement>(null);

  const getAngleIconName = (item: LinkType) =>
    activeSubMenu.name === item ? "angle left" : "angle right";

  if (!props.isOpen) return null;

  return (
    <ContextMenuContainer
      top={Number(props.position.top) + TOP_EDITOR_MENU_HEIGHT_PX}
      left={Number(props.position.left)}
      ref={containerRef}
    >
      <Menu vertical>
        {!props.isLink ? (
          <>
            <Menu.Item
              name="static"
              tabIndex="0"
              active={activeSubMenu.name === "static"}
              onClick={(e, item) =>
                openSubMenu({ e, item: item.name as LinkType })
              }
            >
              Utwórz link
              <Icon name={getAngleIconName("static")} />
            </Menu.Item>
            {/*<Menu.Item
              name="conditional"
              tabIndex="0"
              active={activeSubMenu.name === "conditional"}
              onClick={(e, menuItem) =>
                props.onAction(menuItem.name as LinkType)
              }
            >
              Warunkowy
            </Menu.Item>*/}
          </>
        ) : (
          <Menu.Item
            name="unlink"
            tabIndex="0"
            onClick={() => props.onAction("unlink")}
          >
            Usuń link
          </Menu.Item>
        )}
      </Menu>
      <StaticLinkMenu
        isLink={props.isLink}
        isOpen={activeSubMenu.name === "static"}
        position={activeSubMenu.position}
        onAction={props.onAction}
        storyLine={props.storyLine}
        hasMultipleStoryLines={props.hasMultipleStoryLines}
      />
    </ContextMenuContainer>
  );
};

export default ContextMenu;
