import { FC, useEffect, useState } from "react";
import { Input, InputProps, Menu } from "semantic-ui-react";
import styled from "styled-components";
import { useDebounce } from "../../../services/useDebounce";
import { useLexiaList } from "../../../queries";
import { ILexia } from "../../../story-api";
import { ContextMenuContainer } from "./styled";
import { ContextMenuProps } from "./types";
import { useContextMenu } from "./useContextMenu";
import { LexiaListItem } from "./LexiaListItem";

const LinkLexiaMenu: FC<ContextMenuProps> = (props) => {
  const {
    data: lexiaList,
    isLoading,
    isSuccess,
    error,
  } = useLexiaList(props.storyLine, {
    enabled: !!props.isOpen,
  });

  const [searchPhrase, setSearchPhrase] = useState("");
  const debouncedSearchPhrase = useDebounce(searchPhrase, 100);
  const [filteredLexiaList, setFilteredLexiaList] = useState<ReadonlyArray<ILexia>>(
    lexiaList || []
  );

  useEffect(() => {
    if (!lexiaList) return;
    setFilteredLexiaList(
      lexiaList.filter((lexia) =>
        lexia.title.toLowerCase().includes(debouncedSearchPhrase)
      )
    );
  }, [debouncedSearchPhrase, lexiaList]);

  const { WIDE_MENU_WIDTH } = useContextMenu(props);

  return props.isOpen ? (
    <ContextMenuContainer
      top={Number(props.position.top)}
      left={Number(props.position.left)}
    >
      <WideMenuWrapper width={WIDE_MENU_WIDTH}>
        <Menu vertical>
          <Menu.Item name="title">
            <MenuTitle>Wybierz leksję</MenuTitle>
            <SearchInput
              size="small"
              value={searchPhrase}
              placeholder="Wyszukaj"
              onChange={(_, data) => setSearchPhrase(data.value.toLowerCase())}
            />
          </Menu.Item>
          {isLoading ? <Menu.Item>Loading...</Menu.Item> : null}
          {error ? <Menu.Item>Pobieranie leksji nieudane</Menu.Item> : null}
          {isSuccess
            ? filteredLexiaList.map((lexia) => (
                <BreakWordWrapper key={lexia.url.href}>
                  <Menu.Item
                    as={StyledMenuItem}
                    href={lexia.url.href}
                    onClick={(e) => {
                      e.preventDefault();
                      props.onAction("own-lexia", lexia);
                    }}
                  >
                    <LexiaListItem>{lexia}</LexiaListItem>
                  </Menu.Item>
                </BreakWordWrapper>
              ))
            : null}
        </Menu>
      </WideMenuWrapper>
    </ContextMenuContainer>
  ) : null;
};

const BreakWordWrapper = styled.span`
  .item {
    overflow-wrap: break-word;
  }
`;

const SEARCH_ITEM_HEIGHT_PX = 60;
const ITEM_HEIGHT_PX = 40;

const WideMenuWrapper = styled.div<{ width: number }>`
  // override Semantic UI styles
  .ui.vertical.menu {
    width: ${(p) => p.width}px;
    max-height: ${SEARCH_ITEM_HEIGHT_PX + ITEM_HEIGHT_PX * 8}px;
    overflow-y: auto;
    position: relative;

    div.item:first-child {
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;
      display: flex;
      justify-content: space-between;
      gap: 8px;
      height: ${SEARCH_ITEM_HEIGHT_PX}px;
    }
  }
`;

const SearchInput: FC<InputProps> = styled(Input)`
  width: 0 !important; // Semantic override
  flex: 1;
`;

const MenuTitle = styled.h5`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  min-width: 100px;
`;

const StyledMenuItem = styled.a`
  position: relative;
  height: 2.857em;
`;

export default LinkLexiaMenu;
