import { FC, RefObject, useCallback, useContext, useRef, useState } from 'react';
import { Button, ButtonGroup, Dropdown, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { ChatErrorMessage, ChatService } from '../../../services/chat';
import { ToolbarSplit } from "../../menu/MenuBase";
import { TActiveFormats, TCommand } from '../../menu/MenuTypes';
import { SidebarContext, useSidebarSetter } from "../../menu/SidebarContext";
import ChatSidebar from '../../sidebars/Chat';
import LexiaListSidebar from "../../sidebars/LexiaList";
import { NeighborsMapSidebar } from "../../sidebars/NeighborsMap";
import ColorPickerSubMenu from "./ColorPickerSubMenu";


type EditorMenuToolbarProps = {
  activeFormats: TActiveFormats;
  execCommand: (command: TCommand) => void;
  saveChanges: () => void;
  openNewLexiaModal: () => void;
  openDefaultLinkMenu: (button: HTMLButtonElement) => void;
  openPreview: () => void;
  quit: () => void;
};
const EditorMenuToolbar: FC<EditorMenuToolbarProps> = ({
  activeFormats,
  execCommand,
  saveChanges,
  openNewLexiaModal,
  openDefaultLinkMenu,
  openPreview,
  quit,
}) => {
  const defaultLinkButtonRef = useRef<
    (Button & { ref: RefObject<HTMLButtonElement> }) | null
  >(null);
  const handleDefaultLink = () => {
    if (
      !defaultLinkButtonRef.current ||
      !defaultLinkButtonRef.current.ref.current
    ) return;
    openDefaultLinkMenu(defaultLinkButtonRef.current.ref.current);
  };

  const [messagesInChat, setMessagesInChat] = useState(false);
  const [sidebar, setSidebar] = useContext(SidebarContext);
  ChatService.getInstance().join("test-room", (messages) => {
    if (sidebar !== ChatSidebar) {
      const unseen = messages.some(message => !(message instanceof ChatErrorMessage || message.seen));
      if (messagesInChat !== unseen) {
        setMessagesInChat(unseen);
      }
    }
  });
  const openChatSidebar = useCallback(() => {
    setSidebar(ChatSidebar);
    setMessagesInChat(false);
  }, [sidebar, setSidebar]);

  return <>
      <Button onClick={() => quit()} basic size="small">
        <Icon name="arrow left" />
        Lista linii
      </Button>

      <ButtonGroup basic size="small">
        <Button
          disabled={activeFormats.undo == null}
          icon="undo"
          onClick={() => execCommand("Undo")}
        />
        <Button
          disabled={activeFormats.redo == null}
          icon="redo"
          onClick={() => execCommand("Redo")}
        />
      </ButtonGroup>

      <ButtonGroup basic size="small">
        <Button icon="save" onClick={() => saveChanges()} title="Zapisz" />
        <Button
          icon="newspaper outline"
          onClick={() => openNewLexiaModal()}
          title="Nowy dokument"
        />
        <Button icon="eye" title="Podgląd" onClick={() => openPreview()} />
      </ButtonGroup>

      <ButtonGroup basic size="small">
        <Button
          active={activeFormats.bold}
          icon="bold"
          onClick={() => execCommand("Bold")}
        />
        <Button
          active={activeFormats.italic}
          icon="italic"
          onClick={() => execCommand("Italic")}
        />
        <Button
          active={activeFormats.underline}
          icon="underline"
          onClick={() => execCommand("Underline")}
        />
        <Button
          active={activeFormats.strikethrough}
          icon="strikethrough"
          onClick={() => execCommand("Strikethrough")}
        />
      </ButtonGroup>

      <Dropdown
        icon={null}
        trigger={
          <Button icon basic>
            <Icon name="paint brush" />
          </Button>
        }
        pointing="top right"
      >
        <Dropdown.Menu>
          <ColorPickerSubMenu applyColor={ (color: string) => execCommand(["ForeColor", color]) } />
        </Dropdown.Menu>
      </Dropdown>

      <ButtonGroup basic size="small">
        <Button
          active={activeFormats.alignleft}
          icon="align left"
          onClick={() => execCommand("JustifyLeft")}
        />
        <Button
          active={activeFormats.aligncenter}
          icon="align center"
          onClick={() => execCommand("JustifyCenter")}
        />
        <Button
          active={activeFormats.alignright}
          icon="align right"
          onClick={() => execCommand("JustifyRight")}
        />
        <Button
          active={activeFormats.alignjustify}
          icon="align justify"
          onClick={() => execCommand("JustifyFull")}
        />
      </ButtonGroup>

      <ButtonGroup basic size="small">
        <Button
          active={activeFormats.bullist}
          icon="list ul"
          onClick={() => execCommand("InsertUnorderedList")}
        />
        <Button
          active={activeFormats.numlist}
          icon="list ol"
          onClick={() => execCommand("InsertOrderedList")}
        />
      </ButtonGroup>

      <ButtonGroup basic size="small">
        <Button
          as={ReversedIcon}
          icon="indent"
          onClick={() => execCommand("Outdent")}
        />
        <Button icon="indent" onClick={() => execCommand("Indent")} />
      </ButtonGroup>
      <Button basic icon={"remove format" as any} onClick={() => execCommand("RemoveFormat")} />

      <ToolbarSplit>
        {/*<Button
          ref={defaultLinkButtonRef}
          basic
          size="small"
          onClick={() => handleDefaultLink()}
        >
          Link domyślny
        </Button>*/}

        <Button basic size="small" onClick={useSidebarSetter(NeighborsMapSidebar)}>
          <Icon name="sitemap" />
          Sąsiedztwo
        </Button>

        <Button basic size="small" onClick={useSidebarSetter(LexiaListSidebar)}>
          <Icon name="list layout" />
          Lista leksji
        </Button>

        <BlinkingButton size="small" onClick={openChatSidebar}
                className={messagesInChat ? "primary blinking" : "basic"}>
          <Icon name="chat" />
          Czat
        </BlinkingButton>
      </ToolbarSplit>
  </>;
};

const ReversedIcon = styled.button`
  i {
    transform: translate(1px, 0) rotate(180deg);
  }
`;

const BlinkingButton = styled(Button)`
  &.blinking {
    animation: blinking 1.2s ease-in-out infinite;
  }

  @keyframes blinking {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }
`;

export default EditorMenuToolbar;
